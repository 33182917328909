import React from "react"

const PaypalDonateBtn = () => {
  return (
    <div>
      <form
        action="https://www.paypal.com/donate"
        method="post"
        target="_blank"
      >
        <input type="hidden" name="business" value="HZG98F8H7CSPW" />
        <input
          type="hidden"
          name="item_name"
          value="Contribuisci alla sostituzione della stazione meteo"
        />
        <input type="hidden" name="currency_code" value="EUR" />
        <input
          type="image"
          src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donateCC_LG.gif"
          border="0"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Fai una donazione con il pulsante PayPal"
        />
        <img
          alt="Dona con PayPal"
          border="0"
          src="https://www.paypal.com/it_IT/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </div>
  )
}

export default PaypalDonateBtn
