import React from "react"
import { Link } from "gatsby"
import $ from "jquery"

import headerStyles from "./header.module.scss"

const Header = () => {
  return (
    <nav
      className={`${headerStyles.navbar} navbar fixed-top navbar-expand-md navbar-light bg-light`}
    >
      <Link className="navbar-brand" to="/">
        TaladaMeteo
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              activeClassName="active"
              to="/previsioni"
            >
              Previsioni
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/record">
              Record
              <span className="sr-only">(corrente)</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/tendenze">
              Tendenze
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/stazione">
              Stazione meteo
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contattamiBtn"
              href="/#"
              onClick={e => {
                e.preventDefault()
                $("#contattamiModal").modal("show")
                $(".collapse").collapse("hide")
              }}
            >
              Contattami
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
