import React from "react"
import ScriptTag from "react-script-tag"

import footerStyles from "./footer.module.scss"

const Footer = ({ marginTop }) => {
  const year = new Date().getFullYear()
  return (
    <footer
      className={`${footerStyles.footer} bg-dark text-white`}
      style={marginTop ? { marginTop: marginTop } : {}}
    >
      <div className="container-fluid">
        <div className={`${footerStyles.footerContent} text-center`}>
          <small>© {year} TaladaMeteo</small>
          <div>
            {/* Inizio Codice ShinyStat */}
            <ScriptTag
              type="text/javascript"
              src="//codice.shinystat.com/cgi-bin/getcod.cgi?USER=taladameteo"
              async={true}
            />
            {/* Fine Codice ShinyStat */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
