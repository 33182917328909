import React from "react"

import Header from "./header"
import Footer from "./footer"
import ContattamiModal from "../modals/contattami"
import "../styles/index.scss"
import layoutStyles from "./layout.module.scss"
import StepScroller from "./stepScroller"

const Layout = props => {
  let showStepScroller = props.showStepScroller === false ? false : true
  return (
    <div className={layoutStyles.container}>
      <Header />
      <div className={layoutStyles.pageContent}>{props.children}</div>
      <Footer marginTop={props.footerTopMargin} />
      <ContattamiModal />
      {showStepScroller && <StepScroller />}
    </div>
  )
}

export default Layout
