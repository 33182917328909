import React, { useState, useEffect } from "react"
import $ from "jquery"

import stepScrollerStyles from "./stepScroller.module.scss"

const scrollDuration = 800

export const updateScrollCaret = () => {
  let windowHeight = $(window).height()
  let documentHeight = $(document).height()
  if ($(window).scrollTop() + windowHeight > documentHeight - 20) {
    $("#scroll-caret").addClass(stepScrollerStyles.open)
  } else {
    $("#scroll-caret").removeClass(stepScrollerStyles.open)
  }
}

const StepScroller = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const getScrollTarget = () => {
    try {
      if (
        $(window).scrollTop() + $(window).height() >
        $(document).height() - 20
      ) {
        return "toTop"
      }
      let sections = [...$(".scroll-target"), ...$("section"), $("footer")[0]]
      let navHeight = $("nav").outerHeight()
      for (let i = 0; i < sections.length; i++) {
        const sectionOffset = $(sections[i]).offset().top
        const target = sectionOffset - navHeight
        if ($(window).scrollTop() < target) {
          return target
        }
      }
    } catch (_) {}
    return 0
  }

  const smoothScroll = (target, time) => {
    var margin = -1
    if (!time) {
      time = "1000"
    }
    if (target === "toTop") {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        time
      )
    } else {
      $("html,body").animate(
        {
          scrollTop: target - margin,
        },
        time
      )
    }
  }

  const scrollToTarget = () => {
    let target = getScrollTarget()
    smoothScroll(target, scrollDuration)
  }

  useEffect(() => {
    $(window).on("scroll", () => {
      updateScrollCaret()
    })
    $(window).on("resize", () => {
      updateScrollCaret()
    })
    updateScrollCaret()
    setIsLoaded(true)

    return () => {
      $(window).off("scroll").off("resize")
    }
  }, [])

  return (
    <div id="stepScroller">
      <div
        className={`${stepScrollerStyles.dot} ${
          isLoaded && stepScrollerStyles.loaded
        }`}
        aria-hidden="true"
        onClick={() => {
          scrollToTarget()
        }}
      >
        <i id="scroll-caret" className={stepScrollerStyles.caret}></i>
      </div>
    </div>
  )
}

export default StepScroller
