import React from "react"

import PaypalDonateBtn from "../components/paypalDonateBtn"

const ContattamiModal = () => {
  return (
    <div
      className="modal fade"
      id="contattamiModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="Contattami"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-s">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ciao!</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Chiudi"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-justify">
            <p className="">
              Per qualsiasi domanda o chiarimento riguardante la stazione meteo
              o il sito TaladaMeteo in generale puoi contattarmi scrivendo a:
            </p>
            <p className="text-center">
              <a href="mailto:artanis262@hotmail.it ">artanis262@hotmail.it </a>
            </p>
            <p>
              Se invece vuoi aiutarmi a mantenere online ma sopratutto a
              sostituire la stazione meteo che ultimamente da parecchi
              problemi... Fai una donazione cliccando qui sotto! Grazie {":)"}
            </p>
            <div className="text-center mb-3">
              <PaypalDonateBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContattamiModal
